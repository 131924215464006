<template>
  <v-container id="voting" tag="section">
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <div class="text-center">
      <v-dialog v-model="dialog.value" width="500">
        <v-card>
          <v-card-title class="headline red white--text">
            <v-spacer />
            {{dialog.title}}
            <v-spacer />
          </v-card-title>

          <v-card-text class="mt-5">{{dialog.message}}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-5">
            <v-btn text @click="dialogClear()">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog.action()">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogCalculation.value" width="500">
        <v-card>
          <v-card-title
            :class="dialogCalculation.votingClose?'green':'red'"
            class="headline white--text"
          >
            <v-btn
              dark
              v-if="dialogCalculation.votingClose"
              class="mx-2"
              @click="report(dialogCalculation.id)"
              icon
              small
              color="yellow"
            >
              <v-icon dark>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer />Apuração dos votos
            <v-spacer />
            <h6>{{dialogCalculation.votingClose?'Aberto':'Encerrado'}}</h6>
          </v-card-title>

          <v-card-text class="mt-3 text-center">
            <strong>{{dialogCalculation.title}}</strong>
          </v-card-text>

          <v-simple-table class="pa-5 pt-0">
            <template v-slot:default>
              <thead class="blue">
                <tr>
                  <th class="text-left white--text">Votos</th>
                  <th class="text-center white--text">Quantidade</th>
                  <th class="text-center white--text">Percentual</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in result" :key="index">
                  <td
                    v-for="(value,index) in item"
                    :key="index"
                    :class="index > 0 ?'text-center':''"
                  >{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-actions class="pa-5">
            <h4 class="ml-2">
              <strong>TOTAL:</strong>
              {{dialogCalculation.total}}
            </h4>

            <v-spacer></v-spacer>
            <v-btn text @click="dialogClear()">Sair</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogVotes.value" width="800">
        <v-card>
          <v-card-title class="headline blue white--text">
            <v-spacer />
            {{dialogVotes.text}}
            <v-spacer />
          </v-card-title>
          <v-container>
            <v-btn color="orange" class="ma-2 white--text">
              <export-excel
                class="btn btn-default"
                :data="json_data"
                :fields="json_fields"
                worksheet="Funcionarios"
                :name= nameSheet
              >
                <v-icon left dark>mdi-download</v-icon>
                Exportar Dados
              </export-excel>
            </v-btn>
          </v-container>
          <v-simple-table class="pa-5 mt-2" style="max-height: 400px; overflow: auto;">
            <template v-slot:default>
              <thead class="blue">
                <tr>
                  <th class="text-left white--text">Name</th>
                  <th class="text-left white--text">CPF</th>
                  <th class="text-left white--text">Banco</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in votersAll" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.cpf }}</td>
                  <td>{{ item.bank }}</td>
                </tr>
                <tr v-if="votersAll.length === 0">
                  <td>Nenhum registro encontrado</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-actions class="pa-5">
            <h4 class="ml-2">
              <strong>TOTAL:</strong>
              {{votersAll.length}}
            </h4>

            <v-spacer></v-spacer>
            <v-btn text @click="dialogVotes.value = false">Sair</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-btn color="green" class="ma-2 white--text" to="/pages/voting/create">
      <v-icon left dark>mdi-plus</v-icon>Adicionar
    </v-btn>

    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />
  </v-container>
</template>

<script>
import votingService from "../../../../service/votingService";

export default {
  name: "voting",
  components: {
    Tables: () => import("../../../../components/cms/Tables"),
  },
  data: () => ({
    title: "Votaçoes Cadastradas",
    columns: ["Descrição", "Data inicio", "Data fim", "Encerrada", "Ações"],
    actionsOptions: ["url", "report", "voters", "edit", "delete"],
    linesData: [],
    approvedDialog: false,
    overlay: false,
    nameSheet:'',
    result: [],
    votersAll: [],
    dialogVotes: {
      value: false,
      text: "",
    },
    snackbar: {
      active: false,
      text: "",
    },
    dialog: {
      title: "",
      message: "",
      type: "",
      id: "",
      value: false,
      action: null,
    },

    dialogCalculation: {
      id: 0,
      title: "",
      yes: 0,
      no: 0,
      abstention: 0,
      total: 0,
      id: "",
      action: null,
      votingClose: false,
      value: false,
    },
    json_fields: {},
    json_data: [],
    json_meta: [
        [
            {
                'key': 'charset',
                'value': 'utf-8'
            }
        ]
    ]
  }),
  methods: {
    action($event) {
      switch ($event.type) {
        case "edit":
          this.edit($event.id);
          break;
        case "delete":
          const data = {
            title: "Excluir votação",
            message: "Deseja excluir essa votação?",
            type: "delete",
            id: $event.id,
            action: this.deletevoting,
          };
          this.dialogAction(data);
          break;
        case "report":
          this.report($event.id);
          break;
        case "url":
          this.url($event.id);
          break;
        case "voters":
          this.voters($event.id);
          break;

        default:
          break;
      }
    },
    dialogAction(data) {
      this.dialog = Object.assign({}, data);
      this.dialog.value = true;
    },
    dialogClear() {
      this.dialog = {
        title: "",
        message: "",
        type: "",
        id: "",
        value: false,
        action: null,
      };
      this.dialogCalculation.value = false;

      setTimeout(() => {
        this.dialogCalculation = {
          id: 0,
          title: "",
          yes: 0,
          no: 0,
          abstention: 0,
          total: 0,
          id: "",
          value: false,
          votingClose: false,
          action: null,
        };
      }, 500);

      this.result = [];
    },
    formatDate(date) {
      const insetZero = (num) => (num <= 9 ? `0${num}` : num);
      return `${insetZero(new Date(date).getDate())}/${insetZero(
        new Date(date).getMonth() + 1
      )}/${insetZero(new Date(date).getFullYear())} ${insetZero(
        new Date(date).getHours()
      )}:${insetZero(new Date(date).getMinutes())}`;
    },
    formatPhone(phone){
      const data = String(phone)
      return (`(${data.substring(0,1)}${data.substring(2,1)}) ${data.substring(2)}`)
    },
    formatDateString(date){
      const data = String(date)
      return (`${data.substring(0,2)}/${data.substring(1,3)}/${data.substring(4)}`)
    },
    getAll() {
      this.overlay = true;

      votingService.get({}).then((res) => {
        this.overlay = false;
        this.linesData = res.data.map((voting) => {
          const votingClose =
            new Date(voting.time_open).getTime() < new Date().getTime() &&
            new Date(voting.time_close).getTime() > new Date().getTime();

          return {
            id: voting.id,
            lines: [
              { width: 20, name: voting.description },
              { width: 20, name: this.formatDate(voting.time_open) },
              { width: 15, name: this.formatDate(voting.time_close) },
              { width: 15, name: votingClose ? "Aberto" : "Fechado" },
            ],
          };
        });
      });
    },
    edit(id) {
      this.$router.push({ path: `/pages/voting/edit/${id}` });
    },
    url(id) {
      this.$copyText(`${process.env.VUE_APP_BASE_LOCAL}/votation/${id}`).then(
        (e) => {
          console.log("Copy Url");

          this.snackbar.active = true;
          this.snackbar.text = `A url foi copíada para a área de transfêrencia.`;
        }
      );
      // navigator.clipboard.writeText(`${process.env.VUE_APP_BASE_LOCAL}/votation/${id}`);
      return;
    },
    voters(id) {
      this.overlay = true;
      votingService.get({ id: id, voters: true }).then((res) => {
        const data = res.data;
        const regex = /^[^_\s]*$/;
         this.nameSheet = `Eleitores - ${String(data[0].description).replace(' ',regex)} - ${new Date().getTime()}.xls`
        this.votersAll = data.map((voter) => {
          this.json_fields = {
          'Nome': 'name',
          'Email': 'email',
          'Telefone': 'phone',
          'CPF': 'cpf',
          'Banco': 'bank',
          'Data de Nascimento': 'date_birth',
          'Funcional': 'funcional'
        }

          voter.phone = this.formatPhone(voter.phone)
          voter.date_birth = this.formatDateString(voter.date_birth)
          this.json_data.push(voter)
         
          return {
            name: voter.name,
            cpf: voter.cpf,
            bank: voter.bank,
          };
        });

        this.dialogVotes.text = "Votantes";
        this.dialogVotes.value = true;
        this.overlay = false;

      }).catch(error => {
        this.snackbar.active = true
        this.snackbar.text = "Nenhum registro encontrado.",
        this.overlay = false;
      });
    },
    deletevoting() {
      votingService.delete(this.dialog.id).then((res) => {
        this.getAll();
        this.dialogClear();
      });
    },
    calcPercent(dividend, divider) {
      if (dividend && divider) {
        return ((dividend / divider) * 100).toFixed(2);
      }
      return 0;
    },
    report(id) {
      this.overlay = true;
      votingService.get({ id: id, report: true }).then((res) => {
        const data = res.data[0];
        this.overlay = false;
        const report = {};
        res.data.forEach((reporting) => {
          report[reporting.name] = reporting.value;
        });

        this.result = [];
        this.dialogCalculation.id = id;
        this.dialogCalculation.title =
          data && data.description
            ? data.description
            : this.linesData.find((lines) => lines.id === id).lines[0].name;
        this.dialogCalculation.votingClose =
          data && data.time_open
            ? new Date(data.time_open).getTime() < new Date().getTime() &&
              new Date(data.time_close).getTime() > new Date().getTime()
            : this.linesData.find((lines) => lines.id === id).lines[3].name ===
              "Aberto"
            ? true
            : false;
        this.dialogCalculation.yes = report && report.yes ? report.yes : 0;
        this.dialogCalculation.no = report && report.no ? report.no : 0;
        this.dialogCalculation.abstention =
          report && report.abstention ? report.abstention : 0;
        this.dialogCalculation.total =
          Object.keys(report).length > 0
            ? Object.values(report).reduce(
                (previus, currennt) => previus + currennt
              )
            : 0;
        this.dialogCalculation.value = true;

        this.result.push([
          "SIM",
          this.dialogCalculation.yes,
          `${this.calcPercent(
            this.dialogCalculation.yes,
            this.dialogCalculation.total
          )}%`,
        ]);
        this.result.push([
          "NÂO",
          this.dialogCalculation.no,
          `${this.calcPercent(
            this.dialogCalculation.no,
            this.dialogCalculation.total
          )}%`,
        ]);
        this.result.push([
          "ABSTENÇÂO",
          this.dialogCalculation.abstention,
          `${this.calcPercent(
            this.dialogCalculation.abstention,
            this.dialogCalculation.total
          )}%`,
        ]);
      });
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

